<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"  @datachange="datachange"/>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              type="edit"
              codeGroupCd="WATER_MEASURE_TYPE_CD"
              itemText="codeName"
              itemValue="code"
              label="측정구분"
              name="waterMeasureTypeCd"
              v-model="searchParam.waterMeasureTypeCd"
              @datachange="datachange">
            </c-select>
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              required
              :range="true"
              label="기간"
              name="period"
              type="month"
              defaultStart="-6M"
              defaultEnd="0M"
              v-model="period"
              @datachange="datachange"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              type="search"
              :editable="editable"
              codeGroupCd="WATER_MEASURE_TYPE_CD"
              itemText="codeName"
              itemValue="code"
              label="측정구분"
              name="waterMeasureTypeCd"
              v-model="searchParam.waterMeasureTypeCd"
              @datachange="datachange">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              type="search"
              :comboItems="measurelocItems"
              itemText="envWaterMstMeasurelocName"
              itemValue="envWaterMstMeasurelocId"
              name="envWaterMstMeasurelocId"
              label="측정위치"
              v-model="searchParam.envWaterMstMeasurelocId"
              @datachange="datachange2">
            </c-select>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="float-right gridbtntop" style="margin-left:auto;">
          <q-btn-group outline >
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
        <div class="col-12" style="background:#fff;">
          <apexchart 
            ref="chart1" 
            type="line"
            :height="chart.height" 
            :options="chart.chartOptions" 
            :series="chart.series"></apexchart>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'self-water-measure-graph',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          mainPeriod: [],
          mainPlantCd: null,
        }
      },
    },
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
        waterMeasureTypeCd: null,
        envWaterMstMeasurelocId: null,
      },
      year: [],
      chart: {
        height: '600',
        chartOptions: {
          title: {
            text: '검사항목별 평균 추이분석'
          },
          annotations: {
            yaxis: [],
          },
         // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '항목수치'
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
        series: [
          {
            name: "항목수치",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      isYear: false,
      measurelocItems: [],
      itemListUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // 메인에서 호출한 팝업파라미터
      // if (this.popupParam.mainPlantCd) {
      //   this.searchParam.plantCd = (!this.popupParam.mainPlantCd || this.popupParam.mainPlantCd.length > 3 ? null : this.popupParam.mainPlantCd);
      // }
      this.listUrl = selectConfig.env.water.self.measure.graph.url;
      this.itemListUrl = selectConfig.env.water.mst.measureloc.list.url;

      this.setSize();
      this.getItems();
      this.getList();
    },
    getItems() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.measurelocItems = _result.data;
      },);
    },
    getList(_val) {
      let isAllSearch = false;
      this.chart.chartOptions.annotations.yaxis = [];
      delete this.chart.chartOptions.yaxis.max;
      if (this.searchParam.plantCd.split(',').length > 1) {
        isAllSearch = true;
      }
      if (this.period.length > 0) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];

        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          if (this.searchParam.envWaterMstMeasurelocId) {
            this.chart.series = [];
            let xaxis = []
            let seriesData = [];
            this.$_.forEach(_result.data, _item => {
              xaxis.push(_item.measureDt)
              if (_item.envWaterMstInspectItemIds) {
                let count =0;
                let ids = this.$_.split(_item.envWaterMstInspectItemIds, ',')
                let names = this.$_.split(_item.envWaterMstInspectItemNames, ',')
                let measures = this.$_.split(_item.measures, ',')
              
                this.$_.forEach(ids, __item => {
                  seriesData.push({
                    envWaterSelfMeasureMstId: _item.envWaterSelfMeasureMstId,
                    envWaterMstInspectItemId: __item,
                    name: names[count],
                    measure: measures[count],
                  })
                  if (this.$_.findIndex(this.chart.series, {envWaterMstInspectItemId: __item}) === -1) {
                    this.chart.series.push({
                      envWaterMstInspectItemId: __item,
                      name: names[count],
                      data: [],
                    })
                  }
                  count ++;
                })
              }
            })
            this.$set(this.chart.chartOptions.title, 'text', '측정위치별 검사항목 추이분석')
            this.chart.chartOptions.xaxis.categories = xaxis
            this.$_.forEach(this.chart.series, _item => {
              let realSeriesData = this.$_.filter(seriesData, {envWaterMstInspectItemId: _item.envWaterMstInspectItemId});
              this.$_.forEach(realSeriesData, __item => {
                _item.data.push(__item.measure)
              })
            }) 
            if (_val !== undefined && this.chart.series.length > 0) {
              if (_val.label !== '전체') {
                this.$set(this.chart.chartOptions.title, 'text', '측정위치별 검사항목 추이분석')
                let anos  = this.$_.filter(this.inspectItems, {envWaterMstInspectItemId : _val.value });
                let _datas = [];
                this.$_.forEach(this.chart.series, _items => {
                  this.$_.forEach(_items.data, _item => {
                    _datas.push(parseFloat(_item))
                  })
                }) 
                let _maxVal = Math.max.apply(null, _datas);
                let _legalStandard = anos[0].legalStandard;
                let _innerStandard = anos[0].innerStandard;
                if (_legalStandard > _innerStandard) {
                  if (_maxVal > _legalStandard) {
                    this.chart.chartOptions.yaxis.max = _maxVal;
                  } else {
                    this.chart.chartOptions.yaxis.max = _legalStandard;
                  }
                } else {
                  if (_maxVal > _innerStandard) {
                    this.chart.chartOptions.yaxis.max = _maxVal;
                  } else {
                    this.chart.chartOptions.yaxis.max = _innerStandard;
                  }
                }
                if (anos.length > 0) {
                  this.chart.chartOptions.annotations.yaxis = [
                    {
                      y: _legalStandard,
                      borderColor: '#e53935',
                      label: {
                        borderColor: '#e53935',
                        style: {
                          color: '#fff',
                          background: '#e53935',
                        },
                        text: '법적기준 : ' + String(_legalStandard) + '',
                      }
                    },
                    {
                      y: _innerStandard,
                      borderColor: '#ffd600',
                      label: {
                        borderColor: '#ffd600',
                        style: {
                          color: '#000',
                          background: '#ffd600',
                        },
                        text: '내부기준 : ' + String(_innerStandard) + '',
                      }
                    },
                  ];
                }
              } else {
                this.chart.chartOptions.annotations.yaxis = [];
                delete this.chart.chartOptions.yaxis.max;
              }
            } else {
              this.chart.chartOptions.annotations.yaxis = [];
              delete this.chart.chartOptions.yaxis.max;
            }
          } else {
            this.chart.series = [];
            let xaxis = []

            // series.data length 구하기
            let len = _result.data.filter((_item, _idx) => {
              return _result.data.findIndex((__item, __idx) => {
                return _item.envWaterSelfMeasureMstId === __item.envWaterSelfMeasureMstId
              }) === _idx;
            }).length

            this.$_.forEach(_result.data, _item => {
              let idx = xaxis.indexOf(_item.measureDt) 
              if (idx === -1) {
                xaxis.push(_item.measureDt)
                idx = xaxis.indexOf(_item.measureDt) 
              }
              if (_item.envWaterMstInspectItemIds) {
                if (this.$_.findIndex(this.chart.series, { envWaterMstInspectItemId: _item.envWaterMstInspectItemIds }) === -1) {
                  this.chart.series.push({
                    envWaterMstInspectItemId: _item.envWaterMstInspectItemIds,
                    name: _item.envWaterMstInspectItemNames,
                    data: Array.from({length : len}, () => 0),
                  })
                }
                this.chart.series[this.$_.findIndex(this.chart.series, {envWaterMstInspectItemId: _item.envWaterMstInspectItemIds })].data[idx] = _item.measures
              }
            })
            this.$set(this.chart.chartOptions.title, 'text', '검사항목별 평균 추이분석')
            this.chart.chartOptions.xaxis.categories = xaxis
          }
          setTimeout(() => {
            this.chart.chartWidth = '100%';
          }, 200);
          this.$refs['chart1'].refresh();
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message:
          '기간을 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
    datachange() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.searchParam.envWaterMstMeasurelocId = null;
        this.measurelocItems = _result.data;
        this.getList()
      },);
    },
    datachange2() {
      this.getList()
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.searchParam.envWaterMstInspectItemId = null;
      if (data && data.length > 0) {
        this.searchParam.envWaterMstMeasurelocId = data[0].envWaterMstMeasurelocId;
        this.searchParam.envWaterMstMeasurelocName = data[0].envWaterMstMeasurelocName;
        this.getList()
      }
    },
    removeMeasureloc() {
      this.searchParam.envWaterMstMeasurelocId = '';
      this.searchParam.envWaterMstMeasurelocName = '';
      this.searchParam.envWaterMstInspectItemId = null;
      this.getList()
    },
    setSize() {
      let tempHeight = (window.innerHeight - (250));
      if (tempHeight < 300) {
        tempHeight = 300;
      }
      this.$set(this.chart, 'height', tempHeight)
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>